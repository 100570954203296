import React, { useRef, useState } from 'react';
import {
  Stack, Typography, Table, CircularProgress, Button,
  ModalClose,
  Modal,
  ModalDialog,
} from '@mui/joy';
import { BiCloudUpload } from 'react-icons/bi';
import KanopApi from 'api/openapi/KanopApi';
import { RootState, store } from 'store/Store';
import { toast } from 'react-toastify';
import { handleError } from 'pages/security/Login';

function FileUploadPopup(
  {
    collectionId, collectionLocked, openFileUploadPopup, setOpenFileUploadPopup,
  }:
  { collectionId: string | undefined,
    collectionLocked: boolean,
    openFileUploadPopup: boolean
    setOpenFileUploadPopup: (value: boolean) => void
   },
) {
  const { bearers } = (store.getState() as RootState).system;

  const statusColors = {
    INITIALIZED: 'neutral',
    RECEIVED: 'neutral',
    COMPLETED: 'primary',
    REJECTED: 'danger',
  };

  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    data: FileList,
    error,
    isLoading,
    refetch,
  } = KanopApi.useListFilesCollectionsCollectionIdFilesGetQuery({
    collectionId: collectionId || '',
  });

  function getTimeFromDate(dateString: string | number | Date) {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
  }

  function getYearMonthDay(dateString: string | number | Date) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('plots', file);

      const base = process.env.REACT_APP_API_URL;
      fetch(`${base}/groundTruth/collections/${collectionId}/files`, {
        method: 'POST',
        headers: {
          'Accept-version': 'v1',
          ...(bearers && { authorization: `Bearer ${bearers.access}` }),
        },
        body: formData,
      })
        .then((response) => response.json())
        .then(() => {
          toast.success('File uploaded successfully');
          setLoading(false);
          refetch();
        })
        .catch((errors) => {
          toast.error(handleError(errors.data?.detail, 'form') || 'An error occurred while uploading the file');
          setLoading(false);
        });
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Modal
      open={openFileUploadPopup}
      onClose={() => setOpenFileUploadPopup(false)}
    >
      <ModalDialog>
        <ModalClose />
        <Stack
          sx={{
            width: '100%',
            gap: 1,
            padding: 2,
          }}
        >
          <Typography level="h4" textAlign="center">
            Ground truth files
          </Typography>
          <Table
            borderAxis="x"
            sx={{
              minWidth: '800px',
            }}
          >
            <thead>
              <tr style={{ fontWeight: 'bolder' }}>
                <td>File name</td>
                <td>Created by</td>
                <td>Uploaded on</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {FileList?.files.map((file) => (
                <tr key={file.fileId}>
                  <td>{file.fileName}</td>
                  <td>{file.owner?.split(' | ')[1]}</td>
                  <td>
                    {getYearMonthDay(file.createdAt)}
                    {' '}
                    {getTimeFromDate(file.createdAt)}
                  </td>
                  <td>
                    <Typography
                      color={statusColors[file.status as keyof typeof statusColors] as any}
                    >
                      {file.status.toLowerCase()}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {isLoading ? (
            <Stack marginX="auto">
              <CircularProgress />
            </Stack>
          ) : (
            <Stack marginX="auto" direction="row" gap={1} marginTop={2} width="fit-content">
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".csv, text/csv, .geojson, application/json"
              />
              <Button
                onClick={handleButtonClick}
                loading={loading}
                disabled={collectionLocked}
              >
                <BiCloudUpload size={20} />
              </Button>
              {error && <Typography color="danger">An error occurred while fetching the files</Typography>}
            </Stack>
          )}
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default FileUploadPopup;
