export default class FileUpload {
  draw: any;

  map: any;

  turf: any;

  openPopup: any;

  setCurrentFeature: any;

  container!: HTMLDivElement;

  setOpenFileUploadPopup: any;

  constructor(
    draw: any,
    map: any,
    turf: any,
    openPopup: any,
    setCurrentFeature: any,
    setOpenFileUploadPopup: any,
  ) {
    this.draw = draw;
    this.map = map;
    this.turf = turf;
    this.openPopup = openPopup;
    this.setCurrentFeature = setCurrentFeature;
    this.setOpenFileUploadPopup = setOpenFileUploadPopup;
  }

  onAdd(map: any) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

    const button = document.createElement('button');
    button.className = 'mapbox-gl-draw_ctrl-draw-btn file-upload-button';
    button.type = 'button';
    button.title = 'Upload a CSV or GeoJSON file';

    // Add SVG icon for the button
    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="rgba(0,0,0,0.7)"><path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/></svg>';

    // Create a hidden file input element
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.style.display = 'none';
    fileInput.accept = '.geojson'; // Accept only GeoJSON files (modify as needed)
    fileInput.onchange = (event) => this.handleFileUpload(event);

    // Add click event to the button to trigger the file input
    button.onclick = () => this.setOpenFileUploadPopup(true);

    // Append file input to the container
    this.container.appendChild(fileInput);
    this.container.appendChild(button);
    return this.container;
  }

  handleFileUpload(event: Event) {
    this.setOpenFileUploadPopup(true);
  }

  onRemove() {
    if (this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }
    this.map = undefined;
    this.setCurrentFeature(null);
  }
}
